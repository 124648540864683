module.exports = {
  en: {
    default: true,
    path: 'en',
    locale: 'en-US',
    dateFormat: 'MM/DD/YYYY',
    siteLanguage: 'en',
    ogLanguage: 'en_US',
  },
  zh: {
    path: 'zh',
    locale: 'zh-TW',
    dateFormat: 'DD.MM.YYYY',
    siteLanguage: 'zh',
    ogLanguage: 'zh_TW',
  },
};
