import langs from '../../config/i18n';
import en from '../../i18n/en.json';
import zh from '../../i18n/zh.json';

/**
 * Get this current key's internationalized string.
 *
 * @param lang
 * @param string
 * @returns {*|string}
 */
const i18n = (lang, string) => {
  switch (lang) {
    case 'zh':
      return zh[string] || en[string] || '';
    case 'en':
    default:
      return en[string] || '';
  }
};

/**
 * Get this user's preferred language.
 *
 * @returns {string|null}
 */
const getPreferredLang = () => {
  // If this user already has explicitly set a preference, use that
  try {
    // eslint-disable-next-line no-undef
    const lang = localStorage.getItem('gim_preferred_lang');
    if (lang) return lang;
  } catch (_) { /* do nothing */ }

  // If the browser has a preferred language, use that
  try {
    // eslint-disable-next-line no-undef
    const lang = navigator.language;
    if (/^zh\b/.test(lang)) return 'zh';
  } catch (_) { /* do nothing */ }

  return null;
};

/**
 * Gets the locale that we should show this user.
 *
 * @param availableLocales
 * @returns {string|*}
 */
const getPageLang = ({ availableLocales }) => {
  // If this page only has one language, then we show that language
  if (availableLocales.length === 1) return availableLocales[0];

  const preferredLang = getPreferredLang();
  if (preferredLang) return preferredLang;

  return Object.values(langs).find((d) => d.default).path;
};

/**
 * Determines whether this url already has a lang string
 * in front of it.
 *
 * @param url
 * @returns {boolean}
 */
const isUrlLocalized = (url) => {
  const urlSegments = url.split('/');
  if (urlSegments[0] === '') urlSegments.shift();
  return Object.keys(langs).indexOf(urlSegments[0]) >= 0;
};

/**
 * Removes the locale string from the front of the url
 *
 * @param url
 * @returns {string}
 */
const getBaseUrl = (url) => {
  const urlSegments = url.split('/');
  if (urlSegments[0] === '') urlSegments.shift();
  if (isUrlLocalized(url)) urlSegments.shift();
  return `/${urlSegments.join('/')}`;
};

export {
  getPageLang, getBaseUrl, isUrlLocalized, getPreferredLang, i18n,
};
