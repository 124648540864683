import React from 'react';
import { getPageLang, getBaseUrl } from '../../utils/i18n';

/**
 * Handles the case where a visitor lands on a URL that does not have
 * a locale in the URL, or handles a URL for a page that exists in
 * another language but not the current language.
 *
 * Generally, this will first redirect from a non-localized url to
 * /en/*, and then if the preferred language is not english, it will
 * redirect again.
 *
 * @param pageContext
 * @param location
 * @returns {JSX.Element}
 */
const I18NRedirect = ({ pageContext }) => {
  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    document.location.replace(`/${getPageLang(pageContext)}${getBaseUrl(pageContext.pagePath)}`);
  }, []);
  return (
    <meta
      httpEquiv="refresh"
      content={
        `0; URL=/${getPageLang(pageContext)}${getBaseUrl(pageContext.pagePath)}`
      }
    />
  );
};

export default I18NRedirect;
